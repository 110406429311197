import axios from "axios";
import Cookies from "js-cookie";
import { getApiURL } from "./baseURL";


const cookies = Cookies.withConverter({
    read: function (value, name) {
        if (name === 'token') {
            return value
        }
        return Cookies.converter.read(value, name)
    }
})

const client = axios.create({
    baseURL: getApiURL()
});


export const request = ({ ...options }, internal = true) => {
    const onSuccess = (response) => {
        return response;
    };
    const onError = (error) => {
        if (error?.response?.status === 401) {
            cookies.remove('token');
            cookies.remove('user');
            window.location.replace('/login')
        }
        else {
            window.location.replace('/404')
        }
        return error;
    };
    client.defaults.headers['app-version'] = '100.0'
    client.defaults.headers['ngrok-skip-browser-warning'] = '100.0'
    if (cookies.get("token") != undefined
        && internal
        && client.defaults.headers.Authorization == undefined) {
        client.defaults.headers.Authorization = `${cookies.get("token")}`
    }

    return client(options).then(onSuccess).catch(onError);
};
