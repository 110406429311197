import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { DownLoadTable } from "../../../utils/DownloadtablesInExcel";
import { COLUMNS } from "./Constants";
import moment from 'moment';

function TableLayout({
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setPageSort
}) {
    const { sortBy } = state
    const navigate = useNavigate();

    useEffect(() => {
        setPageSort(sortBy)
    }, [sortBy])

    const ColumnHeaders = COLUMNS.map((column) => column.Header);
    let TableData = rows.map((row) => {
        return [row.original.id, row.original?.name, row.original?.username, row.original.contact_no, row.original?.partnerUsers[0]?.partner?.name, moment().diff(moment(row?.original?.dob), 'years'), row?.original?.inventoryId, row?.original?.isPillUpRegistered]
    })

    return (
        <>
            <button className="bg-elm-500 mt-4 text-white-50 px-4 py-3 rounded-2xl font-sans text-md font-semibold  flex justify-center" onClick={() => DownLoadTable('AllUsersData', 'All Users', ColumnHeaders, TableData)}>
                Download (Excel)</button>
            <div className="block p-2 px-4 bg-[#ffffff] rounded-2xl shadow-soft-xl mt-4">
                <table
                    key="future-orders"
                    {...getTableProps()}
                    className="w-full font-sans pl-4">
                    <thead >
                        {headerGroups.map((headerGroup, id) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={id}
                                className="border-b border-gray-100 h-14 font-sans ">
                                {headerGroup.headers.map((column, id) => (
                                    <>
                                        {column.Header === "Name" || column.Header === "Username" || column.Header === "Phone Number" || column.Header === "Age" || column.Header === "Action" ? (
                                            <th
                                                className="font-sans text-left pl-3"
                                                key={id}
                                                {...column.getHeaderProps()}>
                                                {column.render("Header")}
                                            </th>
                                        ) :
                                            <th
                                                className="font-sans text-left pl-3"
                                                key={id}
                                                {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render("Header")}
                                                <span>
                                                    {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                                                </span>
                                            </th>
                                        }
                                    </>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className="text-left border-t border-gray-100 h-14 hover:bg-slate-200 pl-3 font-sans "
                                    key={rowIndex}>
                                    {row.cells.map((cell, cellIndex) => {
                                        return (
                                            <React.Fragment key={cellIndex}>
                                                {cell.column.Header === '#' && row.original.isPillUpRegistered ?
                                                    <td
                                                        {...cell.getCellProps()}
                                                        onClick={() => { navigate(`/user/${cell.value}?page=1&size=50&search=`) }}
                                                        className="py-4 text-left pl-3 font-sans underline cursor-pointer hover:text-black text-elm-500 font-medium"
                                                        key={cellIndex}>
                                                        {cell.render("Cell")}
                                                    </td>

                                                    : <td
                                                        {...cell.getCellProps()}
                                                        className="text-left pl-3 font-sans"
                                                        key={cellIndex}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                }

                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>

    );
}

export default TableLayout;

// props validation
TableLayout.propTypes = {
    getTableProps: PropTypes.any,
    getTableBodyProps: PropTypes.func.isRequired,
    headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    prepareRow: PropTypes.func.isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object),
    changeRightBarOpen: PropTypes.func.isRequired,
    preGlobalFilteredRows: PropTypes.arrayOf(PropTypes.object),
    setGlobalFilter: PropTypes.func,
    state: PropTypes.objectOf(PropTypes.any),
    setPageSort: PropTypes.any
};
