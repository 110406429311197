import Cookies from "js-cookie";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "../../../config/helpers/axios-instance";
import "../../../styles/Signup.scss";
import { toast } from "react-toastify";
const Contexts = require("../../../contexts");

const cookies = Cookies.withConverter({
    write: function (value, name) {
        if (name === 'token') {
            return value
        } else {
            return value
        }
    }
})

function OTPForm() {
    const { authState, authDispatch } = React.useContext(Contexts.Auth);
    const navigate = useNavigate();
    const ref = useRef()
    const onSubmit = async (e) => {
        e.preventDefault()
        const response = await request({
            url: `user/verifyOtp`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                username: authState.contact_no,
                otp: authState.otp,
            },
        });

        if (response.status === 200 && response.data.error === "") {
            const login = await request({
                url: `user/login`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                auth: {
                    username: authState.contact_no,
                    password: authState.otp,
                },
            });
            if (login.status === 200) {
                cookies.set("token", login.data.data.token, { expires: new Date(new Date().getTime() + 365 * 1440 * 60 * 1000) });
                cookies.set("user", JSON.stringify(login.data.data.user), { expires: new Date(new Date().getTime() + 365 * 1440 * 60 * 1000) });
                navigate("/home", { replace: true });
            }
            else {
                toast.error('Something went wrong!')
            }
        }
        else {
            toast.error('Otp is not correct!')
        }
    };

    useEffect(() => {
        ref.current.focus()
    }, [])
    return (
        <>
            <div className="flex flex-col  w-96 items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <h1 className="font-sans text-6xl font-bold mb-8">
                    PillEngine
                </h1>
                <div className="w-full bg-white-50 rounded-2xl shadow-soft-xl  font-sans">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-left ">
                            OTP has been sent to your mobile
                        </h1>
                        <form onSubmit={(e) => onSubmit(e)}>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Your OTP
                                </label>
                                <input
                                    name="otp"
                                    id="otp"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-2xl focus:ring-primary-600 focus:border-primary-600 block  p-2.5 w-full"
                                    placeholder="Enter Your OTP Here"
                                    maxLength={6}
                                    required={true}
                                    value={authState.otp}
                                    ref={ref}
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value)) {
                                            authDispatch({ ...authState, otp: e.target.value })
                                        }
                                    }

                                    }
                                />
                            </div>

                            <button
                                type="submit"
                                id="signup"
                                className="w-full font-sans text-white-50bg-green-600 hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-2xl text-sm px-5 py-2.5 text-left mt-2">
                                Sign in
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export { OTPForm };
