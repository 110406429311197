import moment from 'moment';
import { create } from 'xmlbuilder2';

const itemMaster = require('./itemMaster.json');

let itemMap = {};

for (let item of itemMaster) {
    itemMap[item.ITEM_NAME] = item.ITEM_CODE;
}

function createXmlFile(payload, patientName, orderId) {
    const xmlContent = payload;
    let filename = `FiliaRX-${patientName}-${orderId}.xml`
    const element = document.createElement("a");
    const file = new Blob([xmlContent], { type: 'text/xml' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
}

function checkIfMedicineToBeTaken(prescriptionItem, date) {
    const checkDate = moment(date);
    const startDate = moment(prescriptionItem.startDate);
    const endDate = moment(prescriptionItem.endDate);

    if (checkDate.isBefore(startDate) || checkDate.isAfter(endDate)) {
        return {};
    }

    const daysSinceStart = checkDate.diff(startDate, 'days');
    const dayOfWeek = checkDate.format('dddd').toLowerCase();

    switch (prescriptionItem.typeOfAdmin) {
        case 'DATE':
            if (checkDate.isSame(startDate, 'day')) {
                return getAdminTimesAndDoses(prescriptionItem);
            }
            break;
        case 'ALTERNATE':
            if (daysSinceStart % 2 === 0) {
                return getAdminTimesAndDoses(prescriptionItem);
            }
            break;
        case 'DAYS':
            if (prescriptionItem[dayOfWeek]) {
                return getAdminTimesAndDoses(prescriptionItem);
            }
            break;
        default:
            console.warn(`Unknown typeOfAdmin: ${prescriptionItem.typeOfAdmin}`);
    }

    return {};
}

function getAdminTimesAndDoses(prescriptionItem) {
    if (prescriptionItem.time.length === 0) {
        // If no specific times are set, assume it's taken once a day
        return {
            'anytime': {
                dose: prescriptionItem.doseSize,
                adminDescription: 'As prescribed'
            }
        };
    }

    return prescriptionItem.time.reduce((result, time, index) => {
        result[time] = {
            dose: prescriptionItem.doseSize,
            adminDescription: prescriptionItem.meal[index]
        };
        return result;
    }, {});
}

function getTimeCategory(time) {
    const hour = parseInt(time.split(':')[0]);
    if (hour >= 4 && hour < 12) {
        return 'Morning.bmp';
    } else if (hour >= 12 && hour < 17) {
        return 'Afternoon.bmp';
    } else if (hour >= 17 && hour < 20) {
        return 'Evening.bmp';
    } else {
        return 'Night.bmp';
    }
}

export function generateFiliaRxXml(orderData) {
    const startDate = moment(orderData.startDate);
    const endDate = moment(orderData.endDate);

    const filiaRxObject = {
        filiaServiceRequest: {
            'requestType': '1',
            basic: {
                '@LocationId': 'OP Pharmacy',
                '@machineNumber': '1',
            },
            patient: {
                '@orderType': '0',
                '@startDate': startDate.format('YYYYMMDD'),
                '@orderNumber': orderData.id,
                '@patientID': orderData.mapUserPatient.Patient.patient_id.replaceAll('-', ''),
                '@patientName': orderData.mapUserPatient.Patient.name,
                pouch: [],
            },
        },
    };

    // Iterate over each date in the range [startDate, endDate]
    let currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate)) {
        const pouchesByTime = {};

        // Process each prescription item for the current date
        orderData.prescription.prescriptionItems.forEach((item) => {
            const timesAndDoses = checkIfMedicineToBeTaken(item, currentDate);

            if (Object.keys(timesAndDoses).length > 0) {
                Object.entries(timesAndDoses).forEach(([time, details]) => {
                    if (!pouchesByTime[time]) {
                        pouchesByTime[time] = {
                            '@flag': '1',
                            '@offsetDays': currentDate.diff(startDate, 'days').toString(),
                            '@doseTime': time,
                            '@layout': '1',
                            drug: [],
                            print: [
                                { '@id': '6', '@text': currentDate.format('DD MMMM YYYY') },
                                { '@id': '7', '@text': time },
                                { '@id': '8', '@text': getTimeCategory(time) },
                                { '@id': '3', '@text': currentDate.format('dddd') },
                                { '@id': '2', '@text': orderData.mapUserPatient.Patient.name },
                            ],
                        };
                    }

                    // Add the drug with a unique print ID (between 10-17)
                    const drugPrintId = 10 + pouchesByTime[time].drug.length;
                    pouchesByTime[time].print.push({
                        '@id': drugPrintId.toString(),
                        '@text': ` ${item.item.name}`,
                    });

                    pouchesByTime[time].print.push({
                        '@id': (drugPrintId + 10).toString(),
                        '@text': details.dose.toString(),
                    });

                    // Add the drug to the pouch
                    pouchesByTime[time].drug.push({
                        '@code': itemMap[`${item.item.name}`] || item.item.mnemonic,
                        '@val': details.dose.toString().replace('.0', ''),
                    });
                });
            }
        });

        // Sort the pouches by doseTime
        const sortedPouches = Object.entries(pouchesByTime)
            .sort(([timeA], [timeB]) => {
                return timeA.localeCompare(timeB);
            })
            .map(([, pouch]) => pouch);

        sortedPouches.forEach((pouch) => {
            filiaRxObject.filiaServiceRequest.patient.pouch.push(pouch);
        });

        currentDate.add(1, 'day');
    }

    // Generate the XML from the object structure
    const xml = create({ version: '1.0', encoding: 'utf-8' })
        .ele(filiaRxObject)
        .end({ prettyPrint: true });

    createXmlFile(xml, orderData.mapUserPatient.Patient.name, orderData.id)
}
