/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useTable } from "react-table";
import PropTypes from "prop-types";
import Modal from "../../../components/Modal";
import CreateAddress from "../../../components/modal-forms/create-address";
import useModal from "../../../hooks/custom-hooks/useModal";
import { CustomModalForm } from "../../../components/modal-forms";


export default function TableLayout({ type, columns, UserData, refetch }) {
    const data = type == 'address' ? UserData.address : UserData.partners;
    const [selectedAddress, setSelectedAddress] = useState(null)
    const { isShowing: isUpdateFormShowing, toggle: toggleUpdateForm } = useModal();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    function openAddressUpdateForm(row) {
        setSelectedAddress(row.original);
        toggleUpdateForm()
    }
    return (
        <div className="block p-2 px-4 bg-[#ffffff] rounded-2xl shadow-soft-xl mt-4">
            {type == 'address' && < CustomModalForm
                isShowing={isUpdateFormShowing}
                hide={toggleUpdateForm}
                title="Update Address"
                style={{ width: "60%", maxWidth: "60%" }}
                className="modal-form">
                <CreateAddress refetch={refetch} toggle={toggleUpdateForm} selectedAddress={selectedAddress} selectedUser={{ name: UserData.name, contact_no: UserData.contact_no, id: UserData.id, }} />
            </CustomModalForm>}

            <table {...getTableProps()} className="w-full font-sans pl-4">
                <thead className="">
                    {headerGroups.map((headerGroup, id) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={id} className="border-b border-gray-100 h-14 font-sans">

                            {headerGroup.headers.map((column, id) => (
                                <th {...column.getHeaderProps()} key={id} className="font-sans text-left pl-3">{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, id) => {
                        prepareRow(row);
                        return (

                            <tr {...row.getRowProps()} onClick={() => { type === 'address' ? openAddressUpdateForm(row) : null }} key={id} className="text-left border-t border-gray-100 h-14 hover:bg-slate-200 pl-3 font-sans">
                                {row.cells.map((cell, id) => {
                                    return <td className="py-4 text-left pl-3 font-sans" key={id} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )

}
TableLayout.propTypes = {
    columns: PropTypes.any,
    data: PropTypes.any,
    type: PropTypes.any


};




