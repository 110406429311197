import React from "react";
import { AiFillCaretDown } from "react-icons/ai";
import PropTypes from "prop-types";
import PrescriptionModal from "../../../edit-orders/components/PrescriptionModal";
import { useState } from "react";

function RightBarContent({ data }) {
    const [opener, setOpener] = React.useState({
        userDetails: true,
        patientDetails: true,
        otherDetails: false,
    });
    const [showPrescription, setShowPrescriptionModal] = useState(false);


    // eslint-disable-next-line no-unused-vars
    const handleClosePrescriptionModal = () => setShowPrescriptionModal(false);
    // eslint-disable-next-line no-debugger
    return (
        <>
            <div className="  w-full overflow-auto  sticky p-4">
                <div className="">
                    <h2
                        className="p-2 px-4 bg-[white] shadow-soft-xl rounded-2xl  font-sans flex justify-between items-center font-bold mb-2"
                        onClick={() => {
                            setOpener({ ...opener, userDetails: !opener.userDetails });
                        }}>
                        User Details <AiFillCaretDown />
                    </h2>
                    {opener.userDetails && (
                        <UserDetails
                            user={data?.data?.mapUserPatient?.User}
                            address={data?.data?.address}
                        />
                    )}
                    <h2
                        className="p-2 mt-5 px-4 bg-[white] shadow-soft-xl rounded-2xl font-sans flex justify-between items-center font-bold  mb-2"
                        onClick={() => {
                            setOpener({ ...opener, patientDetails: !opener.patientDetails });
                        }}>
                        Patient Details <AiFillCaretDown />
                    </h2>
                    {opener.patientDetails && (
                        <UserDetails
                            user={data?.data?.mapUserPatient?.Patient}
                            address={data?.data?.address}
                        />
                    )}
                    <h2
                        className="p-2 mt-5 px-4 bg-[white] shadow-soft-xl rounded-2xl  font-sans flex justify-between items-center font-bold  mb-2"
                        onClick={() => {
                            setOpener({ ...opener, otherDetails: !opener.otherDetails });
                        }}>
                        Other Details <AiFillCaretDown />
                    </h2>
                    {opener.otherDetails && <OtherDetails />}
                    <div className="flex gap-3 justify-end mt-4">
                        <button className="bg-[#168c7c] text-white-50 p-2 px-4 shadow-soft-xl rounded-2xl  font-bold text-sm leading-3 font-sans" >
                            View Prescription
                        </button>
                        <PrescriptionModal onlyShow={true} show={showPrescription} prescription={data.data.prescription} onClose={handleClosePrescriptionModal} />
                        <button className="bg-[#000] text-white-50 py-4 px-4 shadow-soft-xl rounded-2xl  font-bold text-sm leading-3 font-sans">
                            Download OCS
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

RightBarContent.propTypes = {
    data: PropTypes.object,
};

export { RightBarContent };

const UserDetails = ({ user, address }) => {
    return (
        <div className="bg-[white] shadow-soft-xl rounded-2xl flex flex-col gap-2 p-5 ">
            <div className="flex flex-col gap-1">
                <h6 className="font-sans font-bold text-sm leading-3">Full Name</h6>
                <p className="font-sans ">{user?.name}</p>
            </div>
            <div className="flex flex-col gap-1">
                <h6 className="font-sans font-bold text-sm leading-3">Email Id</h6>
                <p className="font-sans ">{user?.username}</p>
            </div>
            <div className="flex justify-between items-center">
                <div className="flex flex-col gap-1">
                    <h6 className="font-sans font-bold text-sm leading-3">
                        Phone Number
                    </h6>
                    <p className="font-sans ">{user?.contact_no}</p>
                </div>
                <div className="flex flex-col gap-1 text-left">
                    <h6 className="font-sans font-bold text-sm leading-3">Source</h6>
                    <p className="font-sans ">{user?.source}</p>
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <h6 className="font-sans font-bold text-sm leading-3">Address</h6>
                <p className="font-sans ">
                    {address?.line}, {user?.state}, {user?.pincode}
                </p>
            </div>
        </div>
    );
};

const OtherDetails = () => (
    <div className="bg-[white] shadow-soft-xl rounded-2xl flex flex-col gap-2 p-5  ">
        <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
                <h6 className="font-sans font-bold text-sm leading-3">Agent Id</h6>
                <p className="font-sans ">2</p>
            </div>
            <div className="flex flex-col gap-1 text-left">
                <h6 className="font-sans font-bold text-sm leading-3">Agent Name</h6>
                <p className="font-sans ">Deepanshu </p>
            </div>
        </div>
    </div>
);

UserDetails.propTypes = {
    user: PropTypes.object,
    address: PropTypes.object,
};
OtherDetails.propTypes = {
    data: PropTypes.object,
};
