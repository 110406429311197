/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react'
import { OrderDetailsData } from '../../contexts'
import { useParams } from 'react-router-dom';
import GetUserDetails from '../../hooks/fetch-hooks/get-user-details/get-user-details';
import RightBar from '../../layouts/Dashboard/components/rightbar';
import AllOrderDetailsRightBar from '../all-orders/components/rightsidebar/rightsidebar_details';
import { useNavigate } from 'react-router-dom';
import { ADDRESS_DETAILS_COLUMNS, PARTNER_DETALIS_COLUMNS, PRESCRIPTIONS_COLUMNS } from './table-user-details/Constants';
import TableLayout from './table-user-details/tableLayout';
import { OrdersTable } from '../all-orders/components';
import GetPrescriptionsByUserId from '../../hooks/fetch-hooks/get-prescriptions/get-all-prescription-of-user';
import PrescriptionTable from './table-prescriptions/table';
import { PRESORTED_ORDER_TYPE } from '../../utils/constants';

export default function UserDetails() {
    const navigate = useNavigate();
    const add_columns = useMemo(() => ADDRESS_DETAILS_COLUMNS);
    const partner_columns = useMemo(() => PARTNER_DETALIS_COLUMNS);
    const [orderData, setData] = useState();
    const { id } = useParams();
    const { data, isLoading, refetch } = GetUserDetails(id);
    const { data: prescriptions, isFetched } = GetPrescriptionsByUserId(id);
    let userData = {}
    let prescriptionsData = [];
    const [rightBarOpen, setRightBarOpen] = useState({
        name: "all-orders",
        open: false,
    });
    const changeRightBarOpen = () => {
        if (!rightBarOpen.open) {
            setRightBarOpen({
                ...rightBarOpen,
                open: true,
            });
        }
    };
    const changeRightBarClose = () => {
        setRightBarOpen({
            ...rightBarOpen,
            open: false,
        });
    };

    if (!isLoading) {
        userData = {
            name: data?.data?.data.name,
            username: data?.data?.data.username,
            contact_no: data?.data?.data.contact_no,
            id: data?.data?.data.id,
            source: data?.data?.data.source,
            address: data?.data?.data.addresses,
            partners: data?.data?.data.partnerUsers,
            User: data?.data?.data.User
        }
    }
    if (isFetched) {
        prescriptionsData = prescriptions?.data?.data?.rows;
    }
    if (isFetched && !isLoading) {
        return (

            <div className='px-7'>
                <h2 onClick={() => { navigate(-1) }} className="mb-2 cursor-pointer font-bold text-xl mt-3 p-2 text-[#28a48b]">&lt; Back</h2>
                <h1 className="mt-3 font-bold text-2xl font-sans">#{userData.id} User Details</h1>
                <div className='flex flex-row gap-[30px] flex-wrap mt-6 mb-5'>
                    <h2 className="flex flex-col font-sans"><span className="text-[#168c7c] font-bold text-lg font-sans">Name</span>{userData.name}</h2>
                    <h2 className="flex flex-col font-sans"><span className="text-[#168c7c] font-bold text-lg font-sans">User Name</span>{userData.username}</h2>
                    <h2 className="flex flex-col font-sans"><span className="text-[#168c7c] font-bold text-lg font-sans">Phone</span>{userData.contact_no}</h2>
                    <h2 className="flex flex-col font-sans"><span className="text-[#168c7c] font-bold text-lg font-sans">Source</span>{userData.source}</h2>
                </div>
                <div className='flex flex-row justify-between flex-1 gap-4 mt-[50px]'>
                    <div className='flex flex-col flex-auto'>
                        <h2 className="text-[#168c7c] font-bold text-lg font-sans">Partners</h2>
                        {userData.partners.length > 0 ? <TableLayout type="partners" columns={partner_columns} UserData={userData} /> : <h2>Not Available</h2>}
                    </div>
                    <div className='flex flex-col flex-auto'>
                        <h2 className="text-[#168c7c] font-bold text-lg font-sans ">Address</h2>
                        {userData.address.length > 0 ? <TableLayout type="address" refetch={refetch} columns={add_columns} UserData={userData} /> : <h2>Not Available</h2>}
                    </div>
                    <div className='flex flex-col flex-auto'>
                        <h2 className="text-[#168c7c] font-bold text-lg font-sans ">Prescription</h2>
                        {prescriptionsData.length > 0 ? < PrescriptionTable columns={PRESCRIPTIONS_COLUMNS} rows={prescriptionsData} /> : <h2>Not Available</h2>}
                    </div>

                </div>
                <div className='mt-[50px]'>
                    <RightBar
                        rightBarOpenAction={changeRightBarOpen}
                        rightBarCloseAction={changeRightBarClose}
                        value={rightBarOpen.open}
                        data={data}>
                        <AllOrderDetailsRightBar {...data} />
                    </RightBar>
                    <OrderDetailsData.Provider value={{ orderData, setData, id, OrderType: PRESORTED_ORDER_TYPE }}>
                        <OrdersTable changeRightBarOpen={changeRightBarOpen} />
                    </OrderDetailsData.Provider>
                </div>
            </div>

        )
    }



}
