import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Avatar from "react-avatar";
import { BsCalendarDate } from "react-icons/bs";
import { FaUserMd, FaPrescription } from "react-icons/fa";
import { getRealtionshipTag } from "../../../../config/helpers/get-relationship-tags";
import { useNavigate } from "react-router";
import { FaLocationArrow } from "react-icons/fa";
import "../../../../styles/Rightsidebar.scss";
import { useModalMain } from "../../../../contexts/modal";
import InvoiceForm from "../../../../components/invoice/InvoiceForm";
import { useAddModalMain } from "../../../../contexts/address-modal/AddModal";
import { request } from "../../../../config/helpers/axios-instance";
import { toast } from "react-toastify";
import { generateOcs } from "../../../../utils/ocs-generator";
import PartnerName from "../../../../components/partner/partner-name";
import { GetOrderDetailsByOrderId } from "../../../../hooks/fetch-hooks/order-details.js/get-order-details-by-id";
import { UpdateOrder } from "../../../../hooks/update-hooks/edit-order/order-data-update";
import { PREVENT_STATUS_UPDATE } from "../../constants";
import MutlipleAddressPrint from "../../../../components/address-print/multipleAddressPrint";
import { generateFiliaRxXml } from "../../../../utils/filiaRXGenerator";

function AllOrderDetailsRightBar({
    id,
    mapUserPatient: user,
    endDate,
    address,
    prescription,
    inventoryId
}) {
    const navigate = useNavigate();
    const { openModal } = useModalMain();
    const { AddopenModal, AddcloseModal } = useAddModalMain();
    const { data, isFetched } = GetOrderDetailsByOrderId(id);
    const [OrderData, setOrderdata] = useState({});

    useEffect(() => {
        setOrderdata(data?.data?.data);
    }, [data])


    const UpdateEvital = async () => {
        const body = {
            eVitalRXOrderNo: inventoryId,
            userOrderId: id
        }
        const response = await request({
            method: "POST",
            url: `evitalrx/userorder`,
            data: body
        })

        if (response?.data?.success) {
            toast.success("Request Successful");
        }
        if (!response?.data?.success) {
            toast.error("Somthing Went Wrong")
        }
    }
    if (!isFetched) {
        return (
            <></>
        )
    }

    async function OcsClickHandler() {
        generateOcs(OrderData);
        if (!PREVENT_STATUS_UPDATE.includes(OrderData.status)) {
            try {
                let response = await UpdateOrder({ status: 'OCS_PROCESSING' }, id);
                if (response.status === 200 && response?.data?.success) {
                    toast.success('Order status is changed to "OCS_PROCESSING"')
                }
                else {
                    toast.error(response?.data?.error || 'Order status is not updated!')
                }
            } catch (error) {
                toast.error('Something Went wrong. Please contact tech team!')
            }
        }


    }

    async function FiliaRXClickHandler() {
        generateFiliaRxXml(OrderData);
        if (!PREVENT_STATUS_UPDATE.includes(OrderData.status)) {
            try {
                let response = await UpdateOrder({ status: 'OCS_PROCESSING' }, id);
                if (response.status === 200 && response?.data?.success) {
                    toast.success('Order status is changed to "OCS_PROCESSING"')
                }
                else {
                    toast.error(response?.data?.error || 'Order status is not updated!')
                }
            } catch (error) {
                toast.error('Something Went wrong. Please contact tech team!')
            }
        }


    }

    const printMultipleAddress = () => {
        let addresses = [];
        addresses.push({ address: address, orderId: id })
        AddopenModal(() => MutlipleAddressPrint(addresses = { addresses }))
    }
    return (

        <div
            id="rightbar"
            className="mt-4"
        >
            {/* USER DETAILS */}
            <div className="flex items-center gap-4 p-4 rounded-2xl">
                <Avatar
                    size="75"
                    facebook-id="invalidfacebookusername"
                    src="https://static.vecteezy.com/system/resources/previews/007/224/856/original/flat-doctor-avatar-for-website-chat-window-vector.jpg"
                    name={user?.User?.name}
                    round="50%"
                />
                <div className="CardTextDiv">
                    <h1 className="font-bold text-xl font-sans">{user?.Patient?.name}</h1>
                    <div className="flex items-center gap-2">
                        <h3 className="font-bold text-xs p-1 font-sans text-[blue] border rounded-2xl border-[#158D7B]">
                            {getRealtionshipTag(user?.relationship)}
                        </h3>
                        <h2 className="font-semibold text-md font-sans rel my-2">
                            {user?.User?.name === user?.Patient?.name
                                ? "Self"
                                : user?.User?.name}
                        </h2>
                    </div>
                </div>
            </div>

            <div className="flex mt-2 p-4 items-center gap-2 text-divs">
                <h3>
                    <FaLocationArrow fontSize={"1.7rem"} id="location" />
                </h3>
                <p>
                    {address ?
                        `${address?.line} ${address?.district}, ${address?.state}` : "Not available"}
                </p>
            </div>
            <div className="flex p-4 py-1 items-center gap-2 text-divs">
                <h3>
                    <BsCalendarDate fontSize={"1.7rem"} id="location" />
                </h3>
                <div>
                    <p className="text-xs font-sans">Order EndDate</p>
                    <p className="text-sm font-bold font-sans">
                        {endDate && endDate.split("T")[0]}
                    </p>
                </div>
            </div>
            <div className="flex mt-2 px-4 py-2 items-center gap-2 text-divs">
                <h3 className="font-sans">
                    <FaUserMd fontSize={"1.5rem"} id="location" />
                </h3>

                <p>{prescription ? prescription?.drName : "Not Available"}</p>
            </div>
            <div className="flex mt-2 px-4 py-2 items-center gap-2 text-divs">
                <h3 className="font-sans">
                    <FaPrescription fontSize={"1.5rem"} id="location" />
                </h3>
                <p>{id}</p>
            </div>

            {/* ACTIONS */}
            <div className="flex gap-2 mt-4 px-4 flex-wrap actions">
                <button
                    id="button"
                    className="p-3 text-lg px-7 font-medium font-sans shadow-soft-xl rounded-2xl bg-[#001524] text-white-50"
                    onClick={() => navigate(`/drx/${id}`, { replace: true })}>
                    DRX
                </button>
                <button
                    id="button"
                    onClick={() => navigate(`/drx/${id}?batch=true`, { replace: true })}
                    className="p-3 text-lg px-7 font-medium font-sans shadow-soft-xl rounded-2xl bg-[#0a9396] text-white-50 prx">
                    WO B.No
                </button>
                {
                    !inventoryId ?
                        <button
                            id="button"
                            className="p-3 text-lg px-7 font-medium font-sans shadow-soft-xl rounded-2xl bg-[#e71d36] text-white-50" onClick={() => openModal(() => InvoiceForm(id))}>
                            Map Evital
                        </button> :
                        <button id="button"
                            className="p-3 text-lg px-7 font-medium font-sans shadow-soft-xl rounded-2xl bg-[#e71d36] text-white-50" onClick={UpdateEvital}>
                            Update Evital
                        </button>
                }
                {
                    address &&
                    <button
                        id="button"
                        className="p-3 text-lg px-7 font-medium font-sans shadow-soft-xl rounded-2xl bg-[blue] text-white-50" onClick={() => printMultipleAddress()}>
                        Print Address
                    </button>
                }
                {OrderData?.prescription && <button
                    id="button"
                    className="p-3 text-lg px-7 font-medium font-sans shadow-soft-xl rounded-2xl bg-[#902b8b] text-white-50" onClick={() => OcsClickHandler()}>
                    OCS
                </button>}
                {
                    OrderData?.prescription
                    &&
                    <button
                        id="button"
                        className="p-3 text-lg px-7 font-medium font-sans shadow-soft-xl rounded-2xl bg-[#902b8b] text-white-50" onClick={() => AddopenModal(() => PartnerName(OrderData, AddcloseModal))}>
                        OCS with Partner
                    </button>
                }
                {
                    OrderData?.prescription
                    &&
                    <button
                        id="button"
                        className="p-3 text-lg px-7 font-medium font-sans shadow-soft-xl rounded-2xl bg-[#902b8b] text-white-50" onClick={() => FiliaRXClickHandler()}>
                        FRX
                    </button>
                }
            </div>
        </div>
    );
}

AllOrderDetailsRightBar.propTypes = {
    mapUserPatient: PropTypes.object,
    address: PropTypes.object,
    prescription: PropTypes.object,
    endDate: PropTypes.string,
    id: PropTypes.any,
    noOfDays: PropTypes.number,
    userMappingId: PropTypes.string,
    transactionId: PropTypes.string,
    addressId: PropTypes.string,
    prescriptionId: PropTypes.string,
    status: PropTypes.string,
    notes: PropTypes.string,
    inventoryId: PropTypes.string,
    Partner: PropTypes.string
};

export default AllOrderDetailsRightBar;
