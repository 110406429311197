function GetSortBy(pageSort) {
    switch (pageSort) {
        case "id":
            return "id"
        case "mapUserPatient.User.name":
            return "userName"
        case "mapUserPatient.Patient.name":
            return "patientName"
        case "mapUserPatient.Patient.contact_no":
            return undefined
        case "mapUserPatient.User.contact_no":
            return undefined
        case "endDate":
            return "endDate"
        case "daysLeft":
            return "endDate"
        case "noOfDays":
            return "noOfDays";
    }
}

export function StatusChecker(statustype) {
    switch (statustype) {
        case "ORDER_DELIVERED":
            return "ORDER DELIVERED"
        case "ORDER_PENDING":
            return "ORDER PENDING"
        case "ORDER_INACTIVE":
            return "ORDER INACTIVE"
        case "ORDER_CANCELLED":
            return "ORDER CANCELLED"
        case "ORDER_UPLOADRX":
            return "ORDER UPLOADRX"
        case "CALL_PENDING":
            return "CALL PENDING"
        case "CALL_PICKED":
            return "CALL PICKED"
        case "RX_SENT":
            return "RX SENT"
        case "confirmRX_PENDING":
            return "confirmRX PENDING"
        case "confirmRX_CONFIRM":
            return "confirmRX CONFIRM"
        case "OCS_PENDING":
            return "OCS PENDING"
        case "OCS_PROCESSING":
            return "OCS PROCESSING"
        case "SHIPPED_PACKAGING":
            return "SHIPPED PACKAGING"
        case "SHIPPED_MEDICINES_PACKED":
            return "SHIPPED MEDICINES PACKED"
        case "SHIPPED_BOX_PACKED":
            return "SHIPPED BOX PACKED"
        case "SHIPPED_READY_TO_SHIP":
            return "SHIPPED READY TO SHIP"
        case "SHIPPED_IN_TRANSIT":
            return "SHIPPED IN TRANSIT"
        case "DELIVERED":
            return "DELIVERED"
        case "CONSENT_PENDING":
            return "CONSENT_PENDING"
        case "CONSENT_GRANTED":
            return "CONSENT_GRANTED"
        case "PAYMENT_PENDING":
            return "PAYMENT_PENDING"
        case "PAYMENT_SUCCESS":
            return "PAYMENT_SUCCESS"
        default:
            return ""
    }
}

export { GetSortBy }

export const Cells = [
    'ORDER_ACTIVE',
    'ORDER_DELIVERED',
    'ORDER_PENDING',
    'ORDER_INACTIVE',
    'ORDER_CANCELLED',
    'ORDER_UPLOADRX',
    'CALL_PENDING',
    'CALL_PICKED',
    'RX_SENT',
    'RX_SEND',
    'confirmRX_PENDING',
    'confirmRX_CONFIRM',
    'OCS_PENDING',
    'OCS_PROCESSING',
    'SHIPPED_PACKAGING',
    'SHIPPED_MEDICINES_PACKED',
    'SHIPPED_BOX_PACKED',
    'SHIPPED_READY_TO_SHIP',
    'SHIPPED_IN_TRANSIT',
    'DELIVERED',
    'ORDER_PLACED'
];