/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import GetAllCountries from "../../../hooks/fetch-hooks/fetch-all/get-all-countries";
import GetAllUsers from "../../../hooks/fetch-hooks/fetch-all/get-all-users";
import { TextField } from "../TextField";
import { request } from "../../../config/helpers/axios-instance"
import { toast } from "react-toastify";
import { useModalMain } from "../../../contexts/modal";
import { GetPartners } from "../../../hooks/fetch-hooks/partners/get-partners";
const Constants = require("./Constants");
import classes from "../../../styles/MedicineForm.module.scss";
import moment from "moment";

function CreateUser() {
    const [searchUser, setsearchUser] = useState('')
    const [searchPartner, setsearchPartner] = useState('')
    const [userName, setUserName] = useState('')
    const { data: users } = GetAllUsers(searchUser, isNaN(searchUser) ? 'name' : 'contact_no');
    const { data: countries } = GetAllCountries();
    const [filteredUsers, setFilteredUsers] = React.useState([]);
    const [partner, setPartner] = useState([])
    const [partnerType, setPartnertype] = useState("")
    const { closeModal, modalTitle, formValues, isEdit, refetchUsers } = useModalMain();
    const [filteredcountries, setFilteredCountries] = React.useState([]);
    const [countryCode, setCountryCode] = useState('')
    const { data: Partners, refetch } = GetPartners(GetPartnerName(partnerType), searchPartner)
    const [stopFetch, setStopFetch] = useState(false)
    const [stopFetch2, setStopFetch2] = useState(false)
    const [userMapping, setUserMapping] = React.useState({
        userId: "",
    });
    const [partnerMapping, setPartnerMapping] = React.useState({
        id: "",
        partnerName: ""
    });

    // Main Function Starts Here

    function GetPartnerName(partnerType) {
        switch (partnerType) {
            case "Doctor":
                return "DOCTOR";
            case "B2B":
                return "BUSINESS";
            default:
                return "DOCTOR"
        }
    }

    useEffect(() => {
        if (partnerType === "Doctor" || partnerType === "B2B") {
            refetch()
        }
    }, [partnerType, searchPartner])


    React.useEffect(() => {
        if (users && !stopFetch) {
            setFilteredUsers(
                users.data?.data?.rows.map((item) => {
                    return { label: item.name, id: item.id, Phone: item.contact_no };
                })
            );
        }
        if (countries) {
            setFilteredCountries(
                countries.data.data.rows.map((item) => {
                    return { label: item.name, id: item.id, countryCode: item.countryCode };
                })
            );
        }
        if (Partners && !stopFetch2) {
            setPartner(
                Partners?.data?.data?.rows?.map((item) => {
                    return { label: item.name, id: item.id };
                })
            );
        }
    }, [users, countries, Partners]);



    const onSubmit = async (values, { resetForm }) => {
        try {
            let OriginalImage;
            if (values.image) {
                let formData = new FormData();
                formData.append('image', values.image);
                OriginalImage = await request({
                    url: "/image/upload",
                    method: 'POST',
                    data: formData,
                })
            }

            const Image = OriginalImage?.data?.data?.image
            if (Image) {
                values["image"] = Image
            }
            values["userId"] = userMapping.userId
            values["partnerId"] = parseInt(partnerMapping.id)
            values['source'] = partnerType

            for (let key in values) {
                if (values[key] === "") delete values[key];
            }

            !values.country ? values["country"] = "India" : values.country
            !values.relationship ? values["relationship"] = "SELF" : values.relationship
            values["gender"] = values["gender"] || 'M'
            let response = await request({ method: isEdit ? "PUT" : "POST", url: isEdit ? `user/admin/id/${formValues.id}` : "user/admin", data: values });

            if (response && values.relationship !== "SELF") {
                let patientId = values.user;
                if (!patientId && patientId === "") {
                    alert("User Mapping Failed");
                    resetForm({ values: '' })
                    return;
                }
            }
            if (response.data.success) {
                toast.success(isEdit ? "User Edited Successfully" : "User added Successfully")
                resetForm({ values: '' })
                closeModal();
                if (isEdit) {
                    refetchUsers()
                }
            }
            else {
                if (typeof (response.data.error) === 'string') {
                    toast.error(response.data.error)
                }
                else {
                    toast.error('Something went wrong !')
                }
            }
        }

        catch (err) {
            toast.error("Wrong data")
        }

    };

    // Handing search
    const handleSearch = (e) => {
        setUserName(e.target.value)
        if (isNaN(e.target.value)) {
            setsearchUser(e.target.value)
            setStopFetch(false)
        }
        else if (e.target.value.length === 10) {
            setsearchUser(e.target.value)
            setStopFetch(false)
        }
    }
    const handleSearch2 = (event) => {
        setsearchPartner(event.target.value)
        setStopFetch2(false)
    }
    if (isEdit) {
        Constants.initialValuesEditUser.name = formValues.name;
        Constants.initialValuesEditUser.username = formValues.username;
        Constants.initialValuesEditUser.contact_no = formValues.contact_no;
        Constants.initialValuesEditUser.dob = moment(formValues.dob).format("YYYY-MM-DD");
        Constants.initialValuesEditUser.source = formValues.source;
        Constants.initialValuesEditUser.gender = formValues.gender;
        Constants.initialValuesEditUser.waNotify = formValues.waNotify;
    }

    return (
        <>
            <div className="pb-2">
                <div className="p-2 ml-2">
                    <h1 className="font-semibold text-3xl font-sans text-white-900">{modalTitle}</h1>
                </div>
                {/* <div className="bg-white-700 w-full h-[1px]"></div> */}
                <Formik initialValues={isEdit ? Constants.initialValuesEditUser : Constants.initialValues} onSubmit={onSubmit}>
                    {(props) => {
                        return (
                            <Form>
                                <div className="mt-4 grid grid-cols-1 gap-4 items-center p-6 py-0 focus:outline-1 peer-active:outline-1">
                                    <TextField
                                        required
                                        label="Name"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Full Name"
                                    />

                                    <TextField
                                        required
                                        label="Email"
                                        name="username"
                                        type="text"
                                        placeholder="Enter Username/Email Address"
                                    />

                                    <div className={isEdit ? "grid grid-cols-1  gap-2 items-center" : "grid grid-cols-2  gap-2 items-center"}>
                                        {
                                            !isEdit && <TextField
                                                required
                                                label="Phone"
                                                name="contact_no"
                                                type="tel"
                                                pattern="^\d{10}$"
                                                placeholder="Enter Phone Number"
                                            />
                                        }

                                        <TextField
                                            label="Date of Birth"
                                            name="dob"
                                            type="date"
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4 grid grid-cols-1 gap-4 items-center p-6 py-0">
                                    <div className={isEdit ? "grid grid-cols-1 items-center gap-2" : "grid grid-cols-2 items-center gap-2"}>
                                        {!isEdit && <div className="border-white-500 p-2 border-2 rounded-2xl" >
                                            <h6 className="font-sans text-xs ">Country</h6>
                                            <Field
                                                required={true}
                                                as="select"
                                                name="country"
                                                className="w-full h-[2rem] p-0 m-0 focus:outline-none active:outline-none border-none active:border-none focus:border-none focus:ring-0">4
                                                {/* <option value={"select Country"}>Select Country</option> */}
                                                {filteredcountries.length > 0 &&
                                                    filteredcountries.map((item) => (
                                                        <option key={item.id} value={item.label}>{item.label}</option>
                                                    ))}
                                            </Field>
                                        </div>}
                                        <div className="border-white-500 p-2 border-2 rounded-2xl">
                                            <h6 className="font-sans text-xs ">Gender</h6>
                                            <Field
                                                required
                                                as="select"
                                                name="gender"
                                                className="w-full h-[2rem] p-0 m-0 focus:outline-none active:outline-none border-none active:border-none focus:border-none focus:ring-0">
                                                {Constants.genders.length > 0 &&
                                                    Constants.genders.map((item, key) => (
                                                        <option key={key} value={item.label} style={{ padding: "7px" }} onClick={setCountryCode(item.countryCode)}>{item.label}</option>
                                                    ))}
                                            </Field>
                                        </div>
                                    </div>
                                    {/* Dynaming Searching*/}
                                    <div className="border-white-500 p-2 border-2 rounded-2xl" style={{ display: isEdit ? 'none' : 'block' }} >
                                        <h6 className="font-sans text-xs ">User Name</h6>
                                        <input autoComplete="off" type="text" name="user" className={classes.inputSearch2} value={userName} onChange={handleSearch} />
                                        {filteredUsers.length > 0 && userName.length > 1 && (
                                            <>
                                                <div className={classes.Dropdown2}>
                                                    <ul>
                                                        {filteredUsers.length > 0 && filteredUsers.map((val, id) => {
                                                            return (
                                                                <li key={id} className='cursor-pointer' onClick={() => { setUserName(val.label), setFilteredUsers([]), setUserMapping({ userId: val.id }), setStopFetch(true) }}>{val.label},{"  "} {val.Phone}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {/* Dynamic Searching Ends Here+= */}

                                </div>
                                <div className="mt-4 grid grid-cols-2 gap-4 items-center p-6 py-0" style={{ display: isEdit ? 'none' : 'grid' }} >
                                    <div className="border-white-500 p-2 border-2 rounded-2xl">
                                        <h6 className="font-sans text-xs ">Relationship</h6>
                                        {/* give padding to the select option and remove the border */}
                                        <Field
                                            className="w-full h-[2rem] p-0 m-0 focus:outline-none active:outline-none border-none active:border-none focus:border-none focus:ring-0"
                                            as="select"
                                            name="relationship" >
                                            {Constants.relationships.map((item, key) => (
                                                <option
                                                    key={key}
                                                    value={item.label}
                                                    selected={key === 0 ? true : false}
                                                >
                                                    {item.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className="border-white-500 p-2 border-2 rounded-2xl">
                                        <h6 className="font-sans text-xs ">Source</h6>
                                        <Field
                                            required
                                            value={isEdit ? partnerType : formValues.source}
                                            as="select"
                                            name="source"
                                            onChange={(event) => {
                                                setPartnertype(event.target.value)
                                            }}
                                            className="w-full h-[2rem] p-0 m-0 focus:outline-none active:outline-none border-none active:border-none focus:border-none focus:ring-0">
                                            {Constants.source.map((item, key) => (
                                                <option key={key}>{item.label}</option>
                                            ))}
                                        </Field>
                                    </div>
                                </div>
                                {/* Doctors Seelect+*/}
                                {partnerType === "Doctor" || partnerType === "B2B" ?
                                    <div className="border-white-500 p-2 border-2 rounded-2xl" style={{ marginTop: "12px", width: "93%", marginLeft: "20px" }}>
                                        <h6 className="font-sans text-xs ">{partnerType}</h6>
                                        <input type="text" name="partner" className={classes.inputSearch2} value={searchPartner} onChange={handleSearch2} required={true} />
                                        {partner.length > 0 && searchPartner.length > 0 && (
                                            <>
                                                <div className={classes.Dropdown2}>
                                                    <ul>
                                                        {partner.length > 0 && partner.map((val, id) => {
                                                            return (
                                                                <li key={id} className='cursor-pointer' onClick={() => { setsearchPartner(val.label), setPartner([]), setPartnerMapping({ id: val.id }), setStopFetch2(true) }}>{val.label}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    : null}
                                {/* ends here */}
                                <div className="mt-4 grid grid-cols-1 gap-4 items-center p-6 py-0">
                                    <div className="flex justify-center">
                                        <div className="w-full rounded-2xl">
                                            <h6 className="font-sans text-sm font-medium mb-2">
                                                Upload Profile Picture
                                            </h6>
                                            <input
                                                onChange={(e) => {
                                                    props.setFieldValue(
                                                        "image",
                                                        e.currentTarget.files[0]
                                                    );
                                                }}
                                                name="image"
                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white-50 bg-clip-padding rounded-2xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white-50focus:border-blue-600 focus:outline-none"
                                                type="file"
                                                id="formFile"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center" style={{ display: isEdit ? 'block' : 'none' }}>
                                        <Field type="checkbox" name="waNotify" />
                                        <label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">WhatsApp Notify</label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full bg-elm-600 font-sans text-white-50 font-semibold py-2 rounded-2xl hover:bg-elm-700 h-[3rem]">
                                        {modalTitle}
                                    </button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </>
    );
}

export { CreateUser };
