const relationships = [
    { label: "SELF", gender: '' },
    { label: "FATHER", gender: 'Male' },
    { label: "MOTHER", gender: 'Female' },
    { label: "BROTHER", gender: 'Male' },
    { label: "SISTER", gender: 'Female' },
    { label: "SON", gender: 'Male' },
    { label: "DAUGHTER", gender: 'Female' },
    { label: "HUSBAND", gender: 'Male' },
    { label: "WIFE", gender: 'Female' },
    { label: "UNCLE", gender: 'Male' },
    { label: "AUNT", gender: 'Female' },
    { label: "GRAND_FATHER", gender: 'Male' },
    { label: "GRAND_MOTHER", gender: 'Female' },
    { label: "GRAND_SON", gender: 'Male' },
    { label: "GRAND_DAUGHTER", gender: 'Female' },
    { label: "NEPHEW", gender: 'Male' },
    { label: "NIECE", gender: 'Female' },
    { label: "OTHER", gender: '' },
];

const source = [
    { label: "Select Source" },
    { label: "Facebook" },
    { label: "Whatsapp" },
    { label: "Website" },
    { label: "B2B" },
    { label: "Doctor" },
    { label: "Other" },
];

const countries = [
    { label: "India", code: "+91" },
    { label: "United States", code: "+1" },
    { label: "United Kingdom", code: "+44" },
    { label: "Australia", code: "+61" },
    { label: "Canada", code: "+1" },
    { label: "China", code: "+86" },
    { label: "France", code: "+33" },
    { label: "Germany", code: "+49" },
]

const genders = [
    { label: "M" },
    { label: "F" },
    { label: "P" }
];

const initialValues = {
    name: "",
    username: "",
    contact_no: "",
    dob: "",
    inActive: false,
    countryCode: "+91",
    country: "",
    source: "",
    gender: "",
    userId: "",
    relationship: "",
    image: "",
    // partnerId:"",
};
const initialValuesEditUser = {
    username: "",
    contact_no: "",
    dob: "",
    source: "",
    gender: "",
    image: "",
    waNotify: ""

}

const CREATE_USER = 'user'

export { relationships, source, countries, genders, CREATE_USER, initialValues, initialValuesEditUser };