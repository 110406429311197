/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from "react";
import TableInstance from "./table-instance";
import PropTypes from "prop-types";
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import { GetSortBy } from "../../utils/sortby";
import { OrderDetailsData } from "../../contexts";
import { COLUMNS_FUCTIONALITIES } from "./Constants";
import { DIGITIZE_ORDER_TYPE, PRESORTED_ORDER_TYPE } from "../../utils/constants";
import GetAllOrders from "../../hooks/fetch-hooks/fetch-all/get-all-orders";
import GetAllReOrders from "../../hooks/fetch-hooks/fetch-all/get-all-reorders";


async function Response(pageindex, pagelimit, search, order, orderBy, userId, OrderType, daysLeft) {
    let response;
    switch (OrderType) {
        case 'UserOrder':
            response = await GetAllOrders({
                userId: userId,
                page: pageindex,
                size: pagelimit,
                orderBy: orderBy
            }, '')
            break;
        case PRESORTED_ORDER_TYPE:
            response = await GetAllOrders({
                page: pageindex,
                size: pagelimit,
                patientName: search,
                userName: search,
                order: order,
                orderBy: orderBy,
                userId: userId
            }, '')

            break;
        case DIGITIZE_ORDER_TYPE:
            response = await GetAllOrders({
                page: pageindex,
                size: pagelimit,
                patientName: search,
                userName: search,
                order: order,
                orderBy: orderBy,
                userId: userId
            }, '?digitize=true')
            break;

        default:
            response = await GetAllReOrders({
                page: pageindex,
                size: pagelimit,
                patientName: search,
                userName: search,
                order: order,
                orderBy: orderBy,
                userId: userId
            }, daysLeft)
            break;
    }
    return response
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};



function OrdersTable({ changeRightBarOpen }) {
    const { OrderType } = useContext(OrderDetailsData);
    const [daysLeft, setDaysLeft] = useState(30);
    const query = useQuery();
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    // Main States
    const { id } = useParams()
    const [tableData, setTableData] = React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const [total, setTotal] = useState(0);
    const [pageSort, setPageSort] = React.useState([])
    //  All States for query
    const [pageindex, setPageIndex] = React.useState(query.get('page') || 1);
    // eslint-disable-next-line
    const [pageSize, setPageSize] = React.useState(50);
    const [value, setValue] = React.useState(query.get('search') || "");
    const [order, setOrder] = React.useState(undefined)
    const [orderBy, setOrderBy] = React.useState(undefined);
    // UseEffect Starts Here


    useEffect(() => {
        if (pageSort.length > 0) {
            setOrder(pageSort[0].desc ? "DESC" : "ASC")
            setOrderBy(GetSortBy(pageSort[0].id))
        }
        else if (pageSort.length < 1) {
            setOrder(undefined)
            setOrderBy(undefined)
        }
    }, [pageSort])
    const getData = async () => {
        let leftDays = daysLeft === '' ? 0 : daysLeft
        const fetchedData = await Response(pageindex, pageSize, value, order, orderBy, id, OrderType, leftDays);
        const newTableData = fetchedData?.data?.data?.rows.map((order) => {
            return {
                ...order,
                daysLeft: Math.floor(
                    (new Date(order.endDate) - new Date()) / (1000 * 60 * 60 * 24) + 2
                ),
            };
        });
        setTableData(newTableData);
        setTotal(fetchedData?.data?.data?.count);
    };

    useEffect(() => {
        getData();
    }, [pageindex, pageSize, value, order, orderBy, daysLeft]);


    useEffect(() => {
        setSearchParams({
            page: pageindex,
            size: pageSize,
            search: value,
        })
    }, [pageindex, pageSize, value]);


    const maxValues = Math.round(total / pageSize);

    const handleDeleteRow = (id) => {
        const newData = tableData.filter((item) => item.id !== id)
        setTableData(newData);
    }

    return (
        <>
            <div className="flex gap-4 items-center relative">
                <input
                    value={value}
                    className="px-6  font-sans w-[80%] bg-slate-100 py-4"
                    onChange={(e) => {
                        setValue(e.target.value);
                        setPageIndex(1)
                    }}
                    placeholder={`${total} records...`}
                    style={{
                        fontSize: "1.1rem",
                        border: "0",
                    }}
                />
                {COLUMNS_FUCTIONALITIES[OrderType].includes("DaysLeft") && < div
                    className="flex justify-end items-center"
                >
                    <input
                        type="number"
                        onChange={(e) => { setDaysLeft(e.target.value) }}
                        className="focus:outline-none border-2 border-gray-300 rounded-2xl font-sans"
                        value={daysLeft}
                        style={{ zIndex: "0" }}
                    />
                </div>}

            </div >


            <TableInstance
                tableData={tableData}
                changeRightBarOpen={changeRightBarOpen}
                pageCount={total}
                setPageSort={setPageSort}
                pageSort={pageSort}
                handleDeleteRow={handleDeleteRow}
                refetchOrders={getData}
            />
            {/* Btns For Pagination  */}
            <div className="btn_div">
                <button onClick={() => setPageIndex((count) => Number(count) - 1)} disabled={pageindex <= 1} className="previous">
                    Previous
                </button>
                <span className="pageindex">
                    {total < 49 ? "Page 1" : `Page ${pageindex} of ${maxValues}`}
                </span>
                <button
                    onClick={() => setPageIndex((count) => Number(count) + 1)}
                    disabled={pageindex >= maxValues}
                    className="next"
                >
                    Next {maxValues}
                </button>
            </div>

        </>
    );
}

export default OrdersTable;

OrdersTable.propTypes = {
    changeRightBarOpen: PropTypes.func.isRequired,
    daysLeft: PropTypes.number,
};