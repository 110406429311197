const COLUMNS = [
    {
        Header: '#',
        accessor: 'id',
    },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Username',
        accessor: 'username',
    },
    {
        Header: 'Phone Number',
        accessor: 'contact_no',
    },
    {
        Header: 'Partner',
        accessor: 'partnerUsers[0].partner.name'
    },
    {
        Header: 'Age',
        accessor: 'dob',
        Cell: ({ value }) => {
            return new Date().getFullYear() - new Date(value).getFullYear()
        }
    },
    {
        Header: 'Inventory ID',
        accessor: 'inventoryId',
    },

]

export { COLUMNS }