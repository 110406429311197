import { toast } from "react-toastify";
import { request } from "../../config/helpers/axios-instance"
import MutlipleAddressPrint from "../address-print/multipleAddressPrint";
import React from "react";

async function fetchAddress(addressId, orderId) {
    let data = await request({
        url: `/address/admin?id=${addressId}`,
        method: "GET",
    })
    return { address: data.data.data.rows[0], orderId }
}

export async function printMultipleAddress(selectedRows, AddopenModal) {
    const addresses = await Promise.all(
        selectedRows.map((row) => {
            return fetchAddress(row.addressId, row.orderId)
        })
    )
    AddopenModal(() => MutlipleAddressPrint({ addresses }));
}
export const isRowSelected = (row, selectedRows) => {
    let preSelectedRows = [...selectedRows];
    for (let index = 0; index < selectedRows.length; index++) {
        const element = preSelectedRows[index];
        if (element.orderId === row.original.id) {
            return index
        }
    }
    return null;
}


const updateOrderStatus = (value, orderId) => {
    return request({
        method: "PUT",
        url: `/userOrder/admin/id/${orderId}`,
        data: {
            status: value
        }
    })
}

export const markAllAsDelivered = async (selectedRows, rows, setSelectedRows, refetchOrders) => {
    let promises = [];
    selectedRows.map((row) => {
        let status = rows.filter((data) => { return row.orderId === data.original.id })[0];
        status = status?.original?.status;
        if (status != 'DELIVERED') {
            return promises.push(updateOrderStatus('DELIVERED', row.orderId))
        }
    })
    try {
        await Promise.all(
            promises
        )
        toast.success('updated status');
        refetchOrders()
        setSelectedRows([])
    } catch (error) {
        toast.error('Error in updating status')
        console.log(error,)
    }

}


export const markAllAsShipInTransit = async (selectedRows, rows, setSelectedRows, refetchOrders) => {
    let promises = [];
    selectedRows.map((row) => {
        let status = rows.filter((data) => { return row.orderId === data.original.id })[0];
        status = status?.original?.status;
        if (status !== 'DELIVERED' && status !== 'SHIPPED_IN_TRANSIT')
            promises.push(updateOrderStatus('SHIPPED_IN_TRANSIT', row.orderId))
    })
    try {
        await Promise.all(
            promises
        )
        toast.success('updated status');
        refetchOrders()
        setSelectedRows([])
    } catch (error) {
        console.log(error)
        toast.error('Error in updating status')
    }
}

export const isInDelhi = (pincode) => {
    if (parseInt(pincode) >= 110001 && parseInt(pincode) <= 110110) {
        return true
    }
    return false
}



export const renderNameColumn = (row) => {
    return <div className="flex flex-row gap-2 items-center">
        <div title={!row.original.address ? 'Address not present' : isInDelhi(row.original.address.pincode) ? 'Delhi' : 'Out of delhi'} className={`rounded-[50%] h-3 w-3 ${!row.original.address ? 'bg-purple-600' : isInDelhi(row.original.address.pincode) ? ' bg-green-500' : ' bg-red-500'}`}></div>
        <h1>
            <b className="text-lg">
                {row.original.mapUserPatient.Patient.name} :
                &nbsp;
            </b>
            <span className="text-elm-500 text-lg">
                {row.original.mapUserPatient.Patient.contact_no}
            </span>
            {row.original.mapUserPatient.User.name !=
                row.original.mapUserPatient.Patient.name && (
                    <>
                        <b className="text-blue-500 text-lg">
                            &nbsp;{" "}
                            {[
                                row.original.mapUserPatient
                                    .relationship === "OLD"
                                    ? ""
                                    : row.original.mapUserPatient.relationship.toLowerCase(),
                            ]}
                        </b>
                        <br />
                        <b className="text-lg">
                            {row.original.mapUserPatient.User.name}:
                        </b>
                        &nbsp;
                        <span className="text-elm-500 text-lg">
                            {row.original.mapUserPatient.User.contact_no}
                        </span>
                    </>
                )}
        </h1>
    </div >
}
